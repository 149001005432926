import React from "react"
import Content from "./Content"
import { kebabCase } from "lodash"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTags } from "@fortawesome/free-solid-svg-icons"
const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  meta_title,
  meta_desc,
  tags,
  title,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div>
      <img src={cover} alt={title} />
      <PostContent content={content} />
      <div className="tagdiv" style={{ marginTop: `4rem` }}>
        <p>Tags</p>
        <FontAwesomeIcon icon={faTags} />
        <ul className="taglist">
          {tags && tags.length
            ? tags.map(tag => (
                <li key={tag + `tag`}>
                  <Link to={`/tags/${kebabCase(tag)}/`}>
                    <button>{tag}</button>
                  </Link>
                </li>
              ))
            : null}
        </ul>
      </div>
    </div>
  )
}

export default ArticleTemplate
