import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { HTMLContent } from "../components/articles/Content"
import ArticleTemplate from "../components/articles/ArticleTemplate"
import Footer from "../components/index/Footer"
import Header from "../components/index/Header"
import "../components/index/layout.css"

const ArticlePage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <div>
      <Header />
      <div className="nonHeroPageHero">
        <div className="pageTitle">
          <h1 id="pageTitle"> Blog: {post.frontmatter.title}</h1>
        </div>
      </div>

      <div className="Container">
        <div className="subContainer">
          <section className="section">
            <div className="container content">
              <div className="articles">
                <div className="article">
                  <ArticleTemplate
                    content={post.html}
                    contentComponent={HTMLContent}
                    cover={post.frontmatter.cover}
                    meta_title={post.frontmatter.meta_title}
                    meta_desc={post.frontmatter.meta_description}
                    tags={post.frontmatter.tags}
                    title={post.frontmatter.title}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        cover
        meta_title
        meta_description
        tags
      }
    }
  }
`
